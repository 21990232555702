import axios from 'axios';

const baseUrl = 'https://api.herhaalpriktegencorona.nl';

class Verification {
  requestCode (waiting_list_id, access_key, postalcode, birthyear, email, phone_number) {
    return axios({
      method: 'POST',
      url: baseUrl + '/v1/public/waiting_list/' + waiting_list_id + '/attendee',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        access_key: access_key,
        postalcode: postalcode,
        birthyear: birthyear,
        email: email,
        phone_number: phone_number,
      }
    })
      .then(function (response) {
        return response.data;
      });
  }

  verify (waiting_list_id, attendeeId, verification_code) {
    return axios({
      method: 'POST',
      url: baseUrl + '/v1/public/waiting_list/' + waiting_list_id + '/attendee/' + attendeeId + '/verification',
      data: {
        verification_code: verification_code
      }
    })
      .then(function (response) {
        return response.data;
      });
  }

  register (voucherCode, birthYear, phonenumber, emailAddress) {
    return axios({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        voucher_code: voucherCode,
        birth_year: birthYear,
        phone_number: phonenumber,
        email_address: emailAddress,
      }
    })
      .then(function (response) {
        return response.data;
      });
  }
}
export { Verification as default };
